/** @jsxImportSource theme-ui */
import Spacer from '@/components/Spacer'
import { pxToRem } from '@/utils'
import Link from 'next/link'
import { Fragment } from 'react'
import { Box, Image } from 'theme-ui'

interface Props {
  link: string
}

const ASSET_URL = '/static/images/home/'

const Banner = ({ link }: Props) => {
  return (
    <Fragment>
      <Link
        href={link}
        sx={{ display: 'block', px: [pxToRem(16), 0] }}
        target="_blank"
        role="link"
        aria-label="home page banner"
      >
        <Box
          sx={{
            position: 'relative',
            width: 'full',
            aspectRatio: '6 / 5',
            display: ['block', 'none'],
          }}
        >
          <Image
            src={`${ASSET_URL}banner_mobile.png`}
            alt="home page banner"
            sx={{ width: 'full' }}
          />
        </Box>
        <Box
          sx={{
            position: 'relative',
            width: 'full',
            aspectRatio: '97 / 9',
            display: ['none', 'block'],
          }}
        >
          <Image src={`${ASSET_URL}banner_pc.png`} alt="home page banner" sx={{ width: 'full' }} />
        </Box>
      </Link>
      <Spacer height={4} />
    </Fragment>
  )
}

export default Banner
